import formValidation from './formValidation.en';
import { IntlMessage } from './models';

const messages: IntlMessage = {
  'general.winearound': 'WineAround',
  'general.cancel': 'Cancel',
  'general.previous': 'Back',
  'general.next': 'Forward',
  'general.end': 'End',
  'general.save': 'Save',
  'general.add': 'Aggiungi',
  'general.edit': 'Edit',
  'general.back': 'Back',
  'general.noResult': 'No records found',
  'general.max': 'Max',
  'general.min': 'Min',
  'general.start': 'Start',
  'general.title': 'Title',
  'general.confirm': 'Confirm',

  'calendarToolbar.today': 'Today',
  'calendarToolbar.previous': 'Previous',
  'calendarToolbar.next': 'Next',
  'calendarToolbar.selectDate': 'Select date',
  'calendarToolbar.day': 'Day',
  'calendarToolbar.week': 'Week',
  'calendarToolbar.month': 'Month',

  'navbar.goToDashboard': 'Go to dashboard',
  'navbar.calendar': 'Calendar',
  'navbar.reservations': 'Reservations',

  'navbar.menu.profile': 'Profile',
  'navbar.menu.billing': 'Billing',
  'navbar.menu.users': 'Users and permissions',
  'navbar.menu.logout': 'Logout',
  'navbar.menu.open': 'Open menu',
  'navbar.notifications': 'Notifications',
  'navbar.desktop': 'Desktop',

  'sidebar.title': 'Your WineAround',
  'sidebar.description': 'At any time you can check the result directly on your online page.',
  'sidebar.bookingButton': 'Booking Button',
  'sidebar.menu.company': 'Company',
  'sidebar.menu.places': 'Places',
  'sidebar.menu.operators': 'Operators',
  'sidebar.menu.tags': 'Tags',
  'sidebar.menu.labels': 'Labels (beta)',
  'sidebar.menu.sealable': 'Sealable (beta)',
  'sidebar.menu.coupon': 'Coupon',
  'sidebar.menu.giftCards': 'Gift cards',
  'sidebar.menu.clubs': 'Clubs (beta)',
  'sidebar.menu.settings': 'Settings',
  'sidebar.menu.help': 'Help',

  'locationSelector.selectLocation': 'Select location',
  'locationSelector.noLocationsFound': 'Locations not found',

  'eventModels.noModelsFound': 'Models not found',
  'eventModels.calendarModelsAll': 'All models',
  'eventModels.calendarModelsFav': 'Favorite models',
  'eventModels.show': 'show',
  'eventModels.hide': 'hide',

  'dashboard.addEventModel': 'Modello evento',

  'login.title': 'Login with your account',
  'login.forgotPassword': "I don't remember my password",
  'login.emailPlaceholder': 'Your email',
  'login.passwordPlaceholder': 'Your password',
  'login.btnSignIn': 'Sign in',
  'login.welcomeTitle': 'Welcome your customer in the best way',
  'login.welcomeDescription':
    'Offer impeccable customer service, from booking events to purchasing your products',
  'login.copyright': 'Copyright © 2020',
  'login.switchLanguage': 'Switch to italian',
  'login.invalidCredentials': 'Invalid email or password',
  'login.noAccount': 'Do not have an account?',
  'login.register': 'Register',

  'locations.title': 'Locations',
  'locations.newLocation': 'New location',
  'locations.name': 'Name',
  'locations.image': 'Image',
  'locations.address': 'Address',
  'locations.languages': 'Languages',
  'locations.actions': 'Actions',
  'locations.calendar': 'Calendar',
  'locations.rooms': 'Rooms',
  'locations.languagesDescription': 'Languages and Description',
  'locations.images': 'Images',
  'locations.position': 'Position',
  'locations.sale': 'Sale',
  'locations.chooseEventLanguage':
    'Indicate the languages in which you will hold your events (You can always modify or add them later)',
  'locations.removeLanguage': 'remove language',

  'locations.confirmDialog.title': 'Delete place',
  'locations.confirmDialog.text':
    '<strong>Warning!</strong> By deleting a place, you will no longer be able to manage the calendar and all related events<br /> Are you sure you want to proceed?',
  'locations.confirmDialog.confirmButton.text': 'Yes, delete place',

  'locationsDialog.generalInfo': 'General info',
  'locationsDialog.address': 'Address',
  'locationsDialog.addressNumber': 'Number',
  'locationsDialog.municipality': 'Municipality',
  'locationsDialog.province': 'Province',
  'locationsDialog.cap': 'CAP',
  'locationsDialog.languages': 'Languages',
  'locationsDialog.languagesDesc':
    'Choose the languages ​​in which you will hold your events (You can always modify or add them later)',
  'locationsDialog.languagesTranslations':
    'For each language you can change the name that will be displayed on your booking page',
  'locationsDialog.deleteLanguage': 'delete language',
  'locationsDialog.assignName':
    'Provide a name that allows you to identify the place (eg. Cantina Al Poggio)',
  'locationsDialog.charsLeft': '({number} chars left)',
  'locationsDialog.locationName': 'Place name',
  'locationsDialog.position': 'Position',
  'locationsDialog.addressDummy': 'eg. Via Roma 27, Milano',
  'locationsDialog.insertAddress':
    'Inserisci l’indirizzo qui sotto per visualizzarlo sulla mappa. Trascina l’indicatore per perfezionare la posizione',
  'locationsDialog.confirmAddress': 'Confirm address',
  'locationsDialog.confirmAddressDesc':
    'Complete, and possibly modify, the data collected by Google Maps of the address of your place. All fields must be filled in',
  'locationsDialog.multiSelectSelected': '{0} languages selected',
  'locationsDialog.selectLanguages': 'Select languages',

  'location.rooms.title': 'Add room to the place',
  'location.rooms.description':
    'You can add rooms to your venue and assign the seating capacity (slot) for each room',
  'location.rooms.newRoom': 'Create new room',
  'location.rooms.table.name': 'name',
  'location.rooms.table.slot': 'Slot',
  'location.rooms.table.actions': 'Actions',
  'location.rooms.table.sharedRoom.tooltip': 'Shared room',
  'location.rooms.delete.title': 'Delete room?',
  'location.rooms.delete.message': 'Confirm deletion of the room?',
  'location.rooms.delete.yes': 'Yes',

  'location.imageGallery.description':
    'Upload the images of your place and drag them to change the order in which they will be displayed',
  'location.imageGallery.chooseNewImage': 'Choose new image',

  'imageChooser.title': 'Choose image/s',
  'imageChooser.description':
    'Select one or more images from the list or upload a new one from your computer',
  'imageChooser.uploadButton': 'Upload another image',
  'imageChooser.selectedImages': ' selected images',

  'imageCrop.title': 'Define image aspect',
  'imageCrop.desc':
    'In the app, the photos may appear with the edges cut off compared to what is displayed below depending on the size of the smartphone, drag the photo trying to put the most representative details in the center of the rectangle to make sure they appear on any device',

  'location.roomEditDialog.title': 'Room and slot management',
  'location.roomEditDialog.name': 'Type a name for the room',
  'location.roomEditDialog.sharedRoom.title': 'Shared room',
  'location.roomEditDialog.sharedRoom.description':
    'this room can hold more than one event at the same time, the events assigned to this room will share its available seats',
  'location.roomEditDialog.newSlotLabel': 'Create new seat slot',
  'location.roomEditDialog.slot.tag': 'Tag',
  'location.roomEditDialog.slot.places': 'Places',

  'eventWizard.title': 'Create new event template',
  'eventWizard.name.label': 'Assign an identifying name',
  'eventWizard.color.label': 'Assign a color',
  'eventWizard.eventType.label': 'What kind of event template do you want to create?',
  'eventWizard.event.bookable': 'Bookable event',
  'eventWizard.event.readonly': 'Read only event',
  'eventWizard.event.liveRoom': 'Live Room',
  'eventWizard.charsLeft': '{number} chars left',
  'eventWizard.params': 'Params',
  'eventWizard.languages': 'Languages',
  'eventWizard.images': 'Images',
  'eventWizard.settings': 'Settings',
  'eventWizard.duration': 'Duration',
  'eventWizard.duration.label': 'Event duration',
  'eventWizard.closing.reservations': 'Closing reservations',
  'eventWizard.closing.reservations.tooltip':
    "Quanto tempo prima dell'inizio dell'evento vuoi chiudere le prenotazioni e oscurarne la visibilità su WineAround? Oltre a tale termine, l'evento non sarà più visibile né prenotabile sul circuito WineAround",
  'eventWizard.hide.duration': 'hide the duration of the event',
  'eventWizard.price': 'Price',
  'eventWizard.price.tooltip':
    'Dai un prezzo esatto o una indicazione di prezzo alla tua offerta. Il prezzo si intende per persona',
  'eventWizard.price.typology': 'Type',
  'eventWizard.euro.person': 'Euro/person',
  'eventWizard.available.seats': 'Available and bookable seats',
  'eventWizard.available.seats.label': 'Available seats',
  'eventWizard.available.seats.label.tooltip':
    'Qual è il numero massimo di persone che possono partecipare a questo tipo di evento? Indicalo qui',
  'eventWizard.minimum.reservations': 'Minimum reservations to activate the event',
  'eventWizard.minimum.reservations.tooltip':
    'Qual è il numero minimo di persone che possono partecipare a questo tipo di evento? Indicalo qui',
  'eventWizard.bookable.seats': 'Bookable seats',
  'eventWizard.bookable.seats.tooltip':
    'Qual è il numero massimo di persone che possono partecipare a questo tipo di evento? Indicalo qui, inserendo 0 questa funzione sarà disattivata',
  'eventWizard.onDemand': 'On demand',
  'eventWizard.fixed': 'Fixed',
  'eventWizard.closureTypes.0': 'Nessuna',
  'eventWizard.closureTypes.30': '30 minuti',
  'eventWizard.closureTypes.60': '1 ora',
  'eventWizard.closureTypes.120': '2 ore',
  'eventWizard.closureTypes.180': '3 ore',
  'eventWizard.closureTypes.360': '6 ore',
  'eventWizard.closureTypes.480': '8 ore',
  'eventWizard.closureTypes.720': '12 ore',
  'eventWizard.closureTypes.1440': '1 giorno',
  'eventWizard.closureTypes.2880': '2 giorni',
  'eventWizard.closureTypes.4320': '3 giorni',
  'eventWizard.closureTypes.5760': '4 giorni',
  'eventWizard.closureTypes.7200': '5 giorni',
  'eventWizard.closureTypes.7640': '6 giorni',
  'eventWizard.closureTypes.10080': '1 settimana',
  'eventWizard.images.title': 'Images',
  'eventWizard.images.description': 'Upload one or more images that represent this event',
  'eventWizard.settings.title': 'Modalità di prenotazione predefinita',
  'eventWizard.settings.description':
    'potrai sempre cambiarla direttamente da calendario sul singolo evento',
  'eventWizard.settings.bookingRealTime':
    'Prenotazione <strong>real-time</strong> (automaticamente confermate)',
  'eventWizard.settings.bookingConfirm':
    'Prenotazione <b>su conferma</b> (dovrai confermare la prenotazione, potrai rifiutarla o spostarla in altro orario )',
  'eventWizard.settings.modelSettings': 'Impostazioni del modello evento',
  'eventWizard.settings.languageManage': 'Gestione lingue evento',
  'eventWizard.settings.closeLanguageOnNewReservation':
    'quando ricevi la prima prenotazione in una data lingua <b>disattiva tutte le altre lingue</b>',
  'eventWizard.settings.closeLanguageOnNewReservation.tooltip': 'Da definire',
  'eventWizard.settings.bookingMode': 'Cambio modalità prenotazione',
  'eventWizard.settings.unpauseOnNewReservation':
    '<b>passa a prenotazioni realtime</b> dopo la prima prenotazione confermata',
  'eventWizard.settings.onNoReservations':
    '<b>torna a prenotazioni su conferma</b> dopo la cancellazione dell’ultima (unica) prenotazione (se tutti disdicono)',
  'eventWizard.settings.privateTour': 'Tour privato',
  'eventWizard.settings.privateTour.tooltip': 'Da definire',
  'eventWizard.settings.hideOnNewReservation': "dopo la prima prenotazione nascondi l'evento",
  'eventWizard.settings.showOnNoReservations':
    "se la prenotazione viene cancellata rendi nuovamente visibile l'evento",
  'eventWizard.settings.autoEventCreation': 'Crea evento uguale in automatico',
  'eventWizard.settings.newGroupOnNewReservation': 'quando viene prenotato',
  'eventWizard.settings.onNewReservation': 'quando hai',
  'eventWizard.settings.availableSeats': 'posti disponibili',
  'eventWizard.settings.upToTimes': 'fino a quante volte?',
  'eventWizard.settings.removeEmptyGroupOnNoReservations':
    'dopo la cancellazione dell’ultima prenotazione cancella eventi uguali che non hanno ancora prenotazioni, per evitare duplicati',

  'location.position.title': 'Address of the place',
  'location.position.mapDragDescription': 'Drag the pointer and place it in the exact spot',

  'models.parameters': 'Parameters',
  'models.languagesDescription': 'Languages and Descriptions',
  'models.images': 'Images',
  'models.imageGallery.description': 'Upload one or more images representing this event',
  'models.settings': 'Settings',
  'models.filters': 'Filters',
  'models.messages': 'Messages',
  'models.roomsOperator': 'Rooms and operators',
  'models.payments': 'Payments',
  'models.tags': 'Tags',
  'models.event.bookable': 'Bookable event',
  'models.event.readonly': 'Read only event',
  'models.event.liveRoom': 'Live Room',
  'models.filters.title': 'Calendar filters',
  'models.filters.description':
    'Set which filters should be applied to your calendar when your availability is shown to the public',
  'models.filters.sameStart': 'SAME_START: hide if reservation exists at the same start time',
  'models.filters.sameStart.description':
    'this event will be hidden if there is another event in the calendar with the same start time that was booked',
  'models.filters.sameLanguages':
    'SAME_LANGUAGE: hide (or show) if there is a reservation for the same language',
  'models.filters.sameLanguages.description':
    'this event will be hidden (or shown) for a specific language requested if there is another event of the same type (or any type) in the calendar with a reservation for that language; in a variable range from the start of the event',
  'models.filters.past': 'past',
  'models.filters.future': 'future',
  'models.filters.considerAllEvents': 'Consider events of <b>all kinds</b>',
  'models.filters.considerSameEvents': 'Only consider events of <b>this same type</b>',
  'models.filters.showExistsAnotherEvent':
    'the event will appear only if another event <b>exists</b> with a reservation in the requested language',
  'models.filters.showNotExistsAnotherEvent':
    'the event will appear only if there is <b>no other event</b> with a reservation in the requested language',
  'models.filters.eventPriority':
    'EVENT_PRIORITY: hide if there is no reservation for lower priority event',
  'models.filters.eventPriority.description':
    'this event will be hidden if there is no other event of the same type in the calendar containing reservations and with a slightly lower level of priority in the step; in a variable range from the start of the event',
  'models.filters.hidePast': 'hide past',
  'models.filters.hideFuture': 'hide future',
  'models.filters.offset.0': '0',
  'models.filters.offset.30': '30 minuti',
  'models.filters.offset.45': '45 minuti',
  'models.filters.offset.60': '1 ora',
  'models.filters.offset.75': '1 ora e 15 minuti',
  'models.filters.offset.90': '1 ora e mezza',
  'models.filters.offset.105': '1 ora e 45 minuti',
  'models.filters.offset.120': '2 ore',
  'models.filters.offset.150': '2 ore e mezza',
  'models.filters.offset.180': '3 ore',
  'models.filters.offset.210': '3 ore mezza',
  'models.filters.offset.240': '4 ore',
  'models.filters.offset.300': '5 ore',
  'models.filters.offset.360': '6 ore',
  'models.filters.offset.420': '7 ore',
  'models.filters.offset.480': '8 ore',
  'models.filters.offset.540': '9 ore',
  'models.filters.offset.600': '10 ore',
  'models.filters.offset.660': '11 ore',
  'models.filters.offset.720': '12 ore',
  'models.payments.title': 'Payments',
  'models.payments.description':
    'Remember that you need to link your <b>Stripe</b> account before you can get paid.',
  'models.payments.enablePayments': 'I want to activate credit card payments for this event',
  'models.tags.title': 'Tags',
  'models.tags.description': 'You can assign tags to categorize your events',

  ...formValidation,
};

export default messages;
