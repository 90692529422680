import formValidation from './formValidation.it';
import { IntlMessage } from './models';

const messages: IntlMessage = {
  'general.winearound': 'WineAround',
  'general.cancel': 'Annulla',
  'general.previous': 'Indietro',
  'general.next': 'Avanti',
  'general.end': 'Fine',
  'general.save': 'Salva',
  'general.add': 'Aggiungi',
  'general.edit': 'Edit',
  'general.back': 'Back',
  'general.noResult': 'Nessun risultato trovato',
  'general.max': 'Massimo',
  'general.min': 'Minimo',
  'general.start': 'Inizia',
  'general.title': 'Titolo',
  'general.confirm': 'Conferma',

  'calendarToolbar.today': 'Oggi',
  'calendarToolbar.previous': 'Precedente',
  'calendarToolbar.next': 'Successivo',
  'calendarToolbar.selectDate': 'Seleziona data',
  'calendarToolbar.day': 'Giorno',
  'calendarToolbar.week': 'Sett.',
  'calendarToolbar.month': 'Mese',

  'navbar.goToDashboard': 'Vai alla dashboard',
  'navbar.calendar': 'Calendario',
  'navbar.reservations': 'Prenotazioni',

  'navbar.menu.profile': 'Profilo',
  'navbar.menu.billing': 'Fatturazione',
  'navbar.menu.users': 'Utenti e permessi',
  'navbar.menu.logout': 'Logout',
  'navbar.menu.open': 'Apri menu',
  'navbar.notifications': 'Notifiche',
  'navbar.desktop': 'Desktop',

  'sidebar.title': 'Il tuo WineAround',
  'sidebar.description':
    'In ogni momento puoi verificare il risultato direttamente sulla tua pagina online',
  'sidebar.bookingButton': 'Booking Button',
  'sidebar.menu.company': 'Azienda',
  'sidebar.menu.places': 'Luoghi',
  'sidebar.menu.operators': 'Operatori',
  'sidebar.menu.tags': 'Tags',
  'sidebar.menu.labels': 'Etichette (beta)',
  'sidebar.menu.sealable': 'Vendibili (beta)',
  'sidebar.menu.coupon': 'Coupon',
  'sidebar.menu.giftCards': 'Carte regalo',
  'sidebar.menu.clubs': 'Clubs (beta)',
  'sidebar.menu.settings': 'Impostazioni',
  'sidebar.menu.help': 'Assistenza',

  'locationSelector.selectLocation': 'Seleziona luogo',
  'locationSelector.noLocationsFound': 'Luoghi non trovati',

  'eventModels.noModelsFound': 'Modelli non trovati',
  'eventModels.calendarModelsAll': 'Tutti i modelli',
  'eventModels.calendarModelsFav': 'Modelli preferiti',
  'eventModels.show': 'mostra',
  'eventModels.hide': 'nascondi',

  'dashboard.addEventModel': 'Modello evento',

  'login.title': 'Accedi con il tuo account',
  'login.forgotPassword': 'Non ricordo la password',
  'login.emailPlaceholder': 'La tua email',
  'login.passwordPlaceholder': 'La tua password',
  'login.btnSignIn': 'Accedi',
  'login.welcomeTitle': 'Welcome your customer in the best way',
  'login.welcomeDescription':
    'Offri un servizio impeccabile al cliente, dalla prenotazione di eventi all’acquisto dei tuoi prodotti',
  'login.copyright': 'Copyright © 2020',
  'login.switchLanguage': 'Switch to english',
  'login.invalidCredentials': 'Email o password non valida',
  'login.noAccount': 'Non hai un account?',
  'login.register': 'Registrati',

  'locations.title': 'Luoghi',
  'locations.newLocation': 'Nuovo luogo',
  'locations.name': 'Nome',
  'locations.image': 'Immagine',
  'locations.address': 'Indirizzo',
  'locations.languages': 'Lingue',
  'locations.actions': 'Azioni',
  'locations.calendar': 'Calendario',
  'locations.rooms': 'Sale',
  'locations.languagesDescription': 'Lingue e Descrizione',
  'locations.images': 'Immagini',
  'locations.position': 'Posizione',
  'locations.sale': 'Sale',
  'locations.chooseEventLanguage':
    'Indica le lingue in cui svolgerai i tuoi eventi (Potrai sempre modificarle o aggiungerle in seguito)',
  'locations.removeLanguage': 'elimina lingua',

  'locations.confirmDialog.title': 'Elimina luogo',
  'locations.confirmDialog.text':
    '<strong>Attenzione!</strong> Eliminando un luogo non si potranno più gestire il calendario e tutti gli eventi collegati<br /> Sei sicuro di voler procedere?',
  'locations.confirmDialog.confirmButton.text': 'Sì, elimina luogo',

  'locationsDialog.generalInfo': 'Informazioni generali',
  'locationsDialog.address': 'Indirizzo',
  'locationsDialog.addressNumber': 'Numero',
  'locationsDialog.municipality': 'Comune',
  'locationsDialog.province': 'Provincia',
  'locationsDialog.cap': 'CAP',
  'locationsDialog.languages': 'Lingue',
  'locationsDialog.languagesDesc':
    'Indica le lingue in cui svolgerai i tuoi eventi (Potrai sempre modificarle o aggiungerle in seguito)',
  'locationsDialog.languagesTranslations':
    'Per ogni lingua puoi modificare il nome che verrà visualizzato nella tua pagina booking',
  'locationsDialog.deleteLanguage': 'elimina lingua',
  'locationsDialog.assignName':
    'Assegna un nome che ti permetta di identificare il luogo (es. Cantina Al Poggio)',
  'locationsDialog.charsLeft': '({number} caratteri rimanenti)',
  'locationsDialog.locationName': 'Nome luogo',
  'locationsDialog.position': 'Posizione',
  'locationsDialog.insertAddress':
    'Inserisci l’indirizzo qui sotto per visualizzarlo sulla mappa. Trascina l’indicatore per perfezionare la posizione',
  'locationsDialog.addressDummy': 'es. Via Roma 27, Milano',
  'locationsDialog.confirmAddress': 'Conferma indirizzo',
  'locationsDialog.confirmAddressDesc':
    "Completa, ed eventualmente modifica, i dati rilevati da Google Maps dell'indirizzo del tuo luogo. Tutti i Campi devono essere compilati",
  'locationsDialog.multiSelectSelected': '{0} lingue selezionate',
  'locationsDialog.selectLanguages': 'Seleziona lingue',

  'location.rooms.title': 'Aggiungi sale al luogo',
  'location.rooms.description':
    'Puoi aggiungere delle sale al tuo luogo e assegnare per ogni sala la capacità di posti (slot)',
  'location.rooms.newRoom': 'Crea nuova sala',
  'location.rooms.table.name': 'Nome',
  'location.rooms.table.slot': 'Slot',
  'location.rooms.table.actions': 'Azioni',
  'location.rooms.table.sharedRoom.tooltip': 'Sala condivisa',
  'location.rooms.delete.title': 'Eliminare sala?',
  'location.rooms.delete.message': 'Confermi eliminazione della sala?',
  'location.rooms.delete.yes': 'Sì',

  'location.imageGallery.description':
    'Carica le immagini del tuo luogo e trascinale per modificare l’ordine con cui verranno visualizzate',
  'location.imageGallery.chooseNewImage': 'Scegli nuova immagine',

  'imageChooser.title': 'Scegli immagine/i',
  'imageChooser.description':
    'Seleziona una o più immagini dall’elenco oppure caricane una nuova dal tuo computer',
  'imageChooser.uploadButton': 'Carica altra immagine',
  'imageChooser.selectedImages': ' immagini selezionate',

  'imageCrop.title': 'Definisci aspetto immagine',
  'imageCrop.desc':
    'In app le foto potrebbero apparire con i bordi tagliati rispetto a quanto visualizzato sotto a seconda della dimensione dello smartphone, trascina la foto cercando di mettere i particolari più rappresentativi al centro del rettangolo per essere certo che appaiano su qualsiasi dispositivo',

  'eventWizard.title': 'Crea nuovo modello evento',
  'eventWizard.name.label': 'Assegna un nome identificativo',
  'eventWizard.color.label': 'Assegna un colore',
  'eventWizard.eventType.label': 'Che tipo di modello evento vuoi creare?',
  'eventWizard.event.bookable': 'Evento prenotabile',
  'eventWizard.event.readonly': 'Evento in sola lettura',
  'eventWizard.event.liveRoom': 'Live Room',
  'eventWizard.charsLeft': 'rimangono {number} caratteri',
  'eventWizard.params': 'Parametri',
  'eventWizard.languages': 'Lingue',
  'eventWizard.images': 'Immagini',
  'eventWizard.settings': 'Impostazioni',
  'eventWizard.duration': 'Durata',
  'eventWizard.duration.label': 'Durata evento',
  'eventWizard.closing.reservations': 'Chiusura prenotazioni',
  'eventWizard.closing.reservations.tooltip':
    "Quanto tempo prima dell'inizio dell'evento vuoi chiudere le prenotazioni e oscurarne la visibilità su WineAround? Oltre a tale termine, l'evento non sarà più visibile né prenotabile sul circuito WineAround",
  'eventWizard.hide.duration': "nascondi la durata dell'evento",
  'eventWizard.price': 'Prezzo',
  'eventWizard.price.tooltip':
    'Dai un prezzo esatto o una indicazione di prezzo alla tua offerta. Il prezzo si intende per persona',
  'eventWizard.price.typology': 'Tipologia',
  'eventWizard.euro.person': 'Euro/persona',
  'eventWizard.available.seats': 'Posti disponibili e prenotabili',
  'eventWizard.available.seats.label': 'Posti disponibili',
  'eventWizard.available.seats.label.tooltip':
    'Qual è il numero massimo di persone che possono partecipare a questo tipo di evento? Indicalo qui',
  'eventWizard.minimum.reservations': "Prenotazioni minime per attivare l'evento",
  'eventWizard.minimum.reservations.tooltip':
    'Qual è il numero minimo di persone che possono partecipare a questo tipo di evento? Indicalo qui',
  'eventWizard.bookable.seats': 'Posti prenotabili',
  'eventWizard.bookable.seats.tooltip':
    'Qual è il numero massimo di persone che possono partecipare a questo tipo di evento? Indicalo qui, inserendo 0 questa funzione sarà disattivata',
  'eventWizard.onDemand': 'Su richiesta',
  'eventWizard.fixed': 'Fisso',
  'eventWizard.closureTypes.0': 'Nessuna',
  'eventWizard.closureTypes.30': '30 minuti',
  'eventWizard.closureTypes.60': '1 ora',
  'eventWizard.closureTypes.120': '2 ore',
  'eventWizard.closureTypes.180': '3 ore',
  'eventWizard.closureTypes.360': '6 ore',
  'eventWizard.closureTypes.480': '8 ore',
  'eventWizard.closureTypes.720': '12 ore',
  'eventWizard.closureTypes.1440': '1 giorno',
  'eventWizard.closureTypes.2880': '2 giorni',
  'eventWizard.closureTypes.4320': '3 giorni',
  'eventWizard.closureTypes.5760': '4 giorni',
  'eventWizard.closureTypes.7200': '5 giorni',
  'eventWizard.closureTypes.7640': '6 giorni',
  'eventWizard.closureTypes.10080': '1 settimana',
  'eventWizard.images.title': 'Immagini',
  'eventWizard.images.description': 'Carica una o più immagini che rappresentino questo evento',
  'eventWizard.settings.title': 'Modalità di prenotazione predefinita',
  'eventWizard.settings.description':
    'potrai sempre cambiarla direttamente da calendario sul singolo evento',
  'eventWizard.settings.bookingRealTime':
    'Prenotazione <b>real-time</b> (automaticamente confermate)',
  'eventWizard.settings.bookingConfirm':
    'Prenotazione <b>su conferma</b> (dovrai confermare la prenotazione, potrai rifiutarla o spostarla in altro orario )',
  'eventWizard.settings.modelSettings': 'Impostazioni del modello evento',
  'eventWizard.settings.languageManage': 'Gestione lingue evento',
  'eventWizard.settings.closeLanguageOnNewReservation':
    'quando ricevi la prima prenotazione in una data lingua <b>disattiva tutte le altre lingue</b>',
  'eventWizard.settings.closeLanguageOnNewReservation.tooltip': 'Da definire',
  'eventWizard.settings.bookingMode': 'Cambio modalità prenotazione',
  'eventWizard.settings.unpauseOnNewReservation':
    '<b>passa a prenotazioni realtime</b> dopo la prima prenotazione confermata',
  'eventWizard.settings.onNoReservations':
    '<b>torna a prenotazioni su conferma</b> dopo la cancellazione dell’ultima (unica) prenotazione (se tutti disdicono)',
  'eventWizard.settings.privateTour': 'Tour privato',
  'eventWizard.settings.privateTour.tooltip': 'Da definire',
  'eventWizard.settings.hideOnNewReservation': "dopo la prima prenotazione nascondi l'evento",
  'eventWizard.settings.showOnNoReservations':
    "se la prenotazione viene cancellata rendi nuovamente visibile l'evento",
  'eventWizard.settings.autoEventCreation': 'Crea evento uguale in automatico',
  'eventWizard.settings.newGroupOnNewReservation': 'quando viene prenotato',
  'eventWizard.settings.onNewReservation': 'quando hai',
  'eventWizard.settings.availableSeats': 'posti disponibili',
  'eventWizard.settings.upToTimes': 'fino a quante volte?',
  'eventWizard.settings.removeEmptyGroupOnNoReservations':
    'dopo la cancellazione dell’ultima prenotazione cancella eventi uguali che non hanno ancora prenotazioni, per evitare duplicati',

  'location.roomEditDialog.title': 'Gestione sale e slot',
  'location.roomEditDialog.name': 'Assegna un nome alla sala',
  'location.roomEditDialog.sharedRoom.title': 'Sala condivisa',
  'location.roomEditDialog.sharedRoom.description':
    'questa sala può contenere più di un evento contemporaneamente, gli eventi assegnati a questa stanza condivideranno i suoi posti disponibili',
  'location.roomEditDialog.newSlotLabel': 'Crea nuovo slot di posti',
  'location.roomEditDialog.slot.tag': 'Tag',
  'location.roomEditDialog.slot.places': 'Posti',

  'location.position.title': 'Indirizzo del luogo',
  'location.position.mapDragDescription': 'Trascina il puntatore e posizionalo nel punto esatto',

  'models.parameters': 'Parametri',
  'models.languagesDescription': 'Lingue e Descrizione',
  'models.images': 'Immagini',
  'models.imageGallery.description': 'Carica una o più immagini che rappresentano questo evento',
  'models.settings': 'Impostazioni',
  'models.filters': 'Filtri',
  'models.messages': 'Messaggi',
  'models.roomsOperator': 'Sale e operatori',
  'models.payments': 'Pagamenti',
  'models.tags': 'Tags',
  'models.event.bookable': 'Evento prenotabile',
  'models.event.readonly': 'Evento in sola lettura',
  'models.event.liveRoom': 'Live Room',
  'models.filters.title': 'Filtri calendario',
  'models.filters.description':
    'Imposta quali filtri dovranno essere applicati al tuo calendario quando la tua disponibilità viene mostrata al pubblico',
  'models.filters.sameStart':
    "SAME_START: nascondi se esiste prenotazione alla stessa ora d'inizio",
  'models.filters.sameStart.description':
    "questo evento verrà nascosto se è presente in calendario un altro evento con la stessa ora d'inizio che è stato prenotato",
  'models.filters.sameLanguages':
    'SAME_LANGUAGE: nascondi (o mostra) se esiste una prenotazione per la stessa lingua',
  'models.filters.sameLanguages.description':
    "questo evento verrà nascosto (o mostrato) per una determinata lingua richiesta se è presente in calendario un altro evento dello stesso tipo (o di qualsiasi tipo) con una prenotazione per suddetta lingua; in un range variabile dall'inizio dell'evento",
  'models.filters.past': 'passato',
  'models.filters.future': 'futuro',
  'models.filters.considerAllEvents': 'Considera eventi di <b>tutti i tipi</b>',
  'models.filters.considerSameEvents': 'Considera solo eventi di <b>questo stesso tipo</b>',
  'models.filters.showExistsAnotherEvent':
    "l'evento comparirà solo <b>se esiste</b> un altro evento con una prenotazione nella lingua richiesta",
  'models.filters.showNotExistsAnotherEvent':
    "l'evento comparirà solo se <b>non esiste</b> un altro evento con una prenotazione nella lingua richiesta",
  'models.filters.eventPriority':
    'EVENT_PRIORITY: nascondi se non esiste prenotazione per evento con priorità inferiore',
  'models.filters.eventPriority.description':
    "questo evento verrà nascosto se non è presente in calendario un altro evento dello stesso tipo contenente prenotazioni e con un livello di priorità nel gradino appena inferiore; in un range variabile dall'inizio dell'evento",
  'models.filters.hidePast': 'Nascondi passato',
  'models.filters.hideFuture': 'Nascondi futuro',
  'models.filters.offset.0': '0',
  'models.filters.offset.30': '30 minuti',
  'models.filters.offset.45': '45 minuti',
  'models.filters.offset.60': '1 ora',
  'models.filters.offset.75': '1 ora e 15 minuti',
  'models.filters.offset.90': '1 ora e mezza',
  'models.filters.offset.105': '1 ora e 45 minuti',
  'models.filters.offset.120': '2 ore',
  'models.filters.offset.150': '2 ore e mezza',
  'models.filters.offset.180': '3 ore',
  'models.filters.offset.210': '3 ore mezza',
  'models.filters.offset.240': '4 ore',
  'models.filters.offset.300': '5 ore',
  'models.filters.offset.360': '6 ore',
  'models.filters.offset.420': '7 ore',
  'models.filters.offset.480': '8 ore',
  'models.filters.offset.540': '9 ore',
  'models.filters.offset.600': '10 ore',
  'models.filters.offset.660': '11 ore',
  'models.filters.offset.720': '12 ore',
  'models.payments.title': 'Pagamenti',
  'models.payments.description':
    'Ricorda che devi collegare il tuo account <b>Stripe</b> prima di poter ricevere pagamenti.',
  'models.payments.enablePayments':
    'Voglio attivare i pagamenti tramite carta di credito per questo evento',
  'models.tags.title': 'Tags',
  'models.tags.description': 'Puoi assegnare dei tag per dare delle categorie ai tuoi eventi',

  ...formValidation,
};

export default messages;
