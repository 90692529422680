import { Dispatch } from 'redux';
import getClient from 'services/apiClientProvider';
import { DefaultApi, InlineResponse2002 } from 'services/apiService';
import { SET_LANGUAGE_TRANSLATIONS } from './actions';
import { SetLanguageTranslation } from './types';

export const fetchLanguageTranslationsSuccess = (
  languageTranslations: InlineResponse2002[]
): SetLanguageTranslation => ({
  type: SET_LANGUAGE_TRANSLATIONS,
  languageTranslations,
});

export const fetchLanguageTranslations = () => {
  return async (dispatch: Dispatch) => {
    try {
      const response = await getClient(DefaultApi).languagesGet();
      dispatch(fetchLanguageTranslationsSuccess(response.data));
    } catch (error) {
      console.log(error);
    }
  };
};
