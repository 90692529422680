import React, { lazy, Suspense, useEffect } from 'react';
import Navbar from './components/layout/navbar/Navbar';
import Main from './components/layout/Main';
import { Router, Route, Switch } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import messages from './lang/messages';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from './state/store';
import Sidebar from './components/layout/sidebar/Sidebar';
import ProtectedRoute from './components/ProtectedRoute';
import { history } from './router/router';
import { fetchLanguageTranslations } from 'state/languageTranslations/actionCreators';

const Dashboard = lazy(() => import('./pages/dashboard/Dashboard'));
const Login = lazy(() => import('./pages/login/Login'));
const Locations = lazy(() => import('./pages/locations/Locations'));
const LocationDetail = lazy(() => import('./pages/locations/locationDetail/LocationDetail'));
const EventModelDetail = lazy(() => import('pages/events/eventModelDetail/EventModelDetail'));

const connector = connect(
  (state: RootState) => ({
    language: state.language.language,
    languageTranslations: state.languageTranslations,
  }),
  {
    fetchLanguageTranslations,
  }
);

type Props = ConnectedProps<typeof connector>;

const App = ({ language, fetchLanguageTranslations }: Props) => {
  useEffect(() => {
    fetchLanguageTranslations();
  }, [fetchLanguageTranslations]);

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <Router history={history}>
        <Switch>
          <Route path="/login">
            <Suspense fallback={<div>Loading...</div>}>
              <Login />
            </Suspense>
          </Route>

          <ProtectedRoute path="/">
            <Navbar />
            <Sidebar />
            <Main>
              <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                  <Route path="/" exact>
                    <Dashboard />
                  </Route>
                  <Route path="/locations" exact>
                    <Locations />
                  </Route>
                  <Route path="/location/:id" exact>
                    <LocationDetail />
                  </Route>
                  <Route path="/event-model/:id" exact>
                    <EventModelDetail />
                  </Route>
                </Switch>
              </Suspense>
            </Main>
          </ProtectedRoute>
        </Switch>
      </Router>
    </IntlProvider>
  );
};

export default connector(App);
